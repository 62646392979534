// Helper to do an ajax post with json content instead of form data
//#region $.PostJson

$.postJson = function (url, data, success, error) {
  return $.ajax({
    url: url,
    type: "POST",
    dataType: "json", // expected format for response
    contentType: "application/json", // send as JSON
    headers: {
      RequestVerificationToken: getCookie('CSRF-TOKEN'),
    },
    data: data,
    success: success,
    error: error,
    complete: function () {
      if ($('.saveButton')) {
        $('.saveButton').attr('disabled', false);
      }
    },
    beforeSend: function () {
      if ($('.saveButton')) {
        $('.saveButton').attr('disabled', true);
      }
    }
  });
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

//#endregion

// Turn a form into a javascript object
//#region $.fn.serializeObject

$.fn.serializeObject = function () {
  var o = {};
  var a = this.serializeArray();
  $.each(a, function () {
    if (o[this.name] !== undefined) {
      if (!o[this.name].push) {
        o[this.name] = [o[this.name]];
      }
      o[this.name].push(this.value || '');
    } else {
      o[this.name] = this.value || '';
    }
  });
  return o;
};

//#endregion

//This is used to make the browser do a form post directly from javascript.  This is useful when a button action should execute a non-ajax form post
//#region Post Form

function post(path, parameters) {
  var form = $('<form></form>');

  form.attr("method", "post");
  form.attr("action", path);
  if (parameters) {
    $.each(parameters, function (key, value) {
      var field = $('<input></input>');

      field.attr("type", "hidden");
      field.attr("name", key);
      field.attr("value", value);

      form.append(field);
    });
  }

  //Add request verification token
  var field = $('<input></input>');

  field.attr("type", "hidden");
  field.attr("name", "__RequestVerificationToken");
  field.attr("value", getCookie('CSRF-TOKEN'));

  form.append(field);

  // The form needs to be a part of the document in
  // order for us to be able to submit it.
  $(document.body).append(form);
  form.submit();
}

//#endregion

//This is used to help alet the user that a calculation has changed (currently in disbursement screens)
//based on: http://stackoverflow.com/questions/275931/how-do-you-make-an-element-flash-in-jquery
//#region AnimateHighlight

var toHighlight = [];
var notLocked = true;

$.fn.animateHighlight = function () {
  toHighlight.push($(this).prop('id'));

  return this;
};

$.fn.triggerAnimateHighlight = function (duration, highlightColor) {
  if (notLocked) {
    notLocked = false;

    var elements = '';
    $(toHighlight).each(function (i, e) {
      if (elements) {
        elements += ','
      }
      elements += ('#' + e);
    });

    var highlightBg = highlightColor || "#f0fff0";
    var animateMs = duration || 1500;
    var originalBg = $(elements).css("backgroundColor");
    $(elements).stop().css("background-color", highlightBg)
      .animate({ backgroundColor: originalBg }, animateMs);
    setTimeout(function () { notLocked = true; }, animateMs);

    toHighlight = [];
  }

  return this;
};

//#endregion

//http://stackoverflow.com/questions/3108986/gaussian-bankers-rounding-in-javascript
function evenRound(num, decimalPlaces) {
  if (!num) {
    return num;
  }

  var d = decimalPlaces || 2;
  var m = Math.pow(10, d);
  var n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  var i = Math.floor(n), f = n - i;
  var e = 1e-8; // Allow for rounding errors in f
  var r = (f > 0.5 - e && f < 0.5 + e) ?
    ((i % 2 == 0) ? i : i + 1) : Math.round(n);
  return (d ? r / m : r);
}

$.fn.fixDecimalFormat = function () {
  var val = this.val();

  if (val) {
    val = fixDecimalFormat(val);

    return this.val(val);
  }

  return this;
};

function fixDecimalFormat(input, commaSeparate) {
  var val = input.toString();

  var decimalIndex = val.indexOf(".");

  if (decimalIndex < 0) {
    val += '.00';
  }
  else {
    var numChangeDigits = val.length - 1 - decimalIndex;

    if (numChangeDigits == 1) {
      val += '0';
    }
    else if (numChangeDigits == 0) {
      val += '00';
    }
  }

  decimalIndex = val.indexOf(".");
  var currencyIndex = val.indexOf("$");

  if (currencyIndex < 0) {
    if (decimalIndex == 0) {
      val = '00' + val;
    }
    else if (decimalIndex == 1) {
      val = '0' + val;
    }
  }
  else {
    var negativeIndex = val.indexOf("-");

    if (negativeIndex < 0) {
      if (decimalIndex == 1) {
        val = '$00' + val.replace('$', '');
      }
      else if (decimalIndex == 2) {
        val = '$0' + val.replace('$', '');
      }
    }
    else {
      if (decimalIndex == 3) {
        val = '- $00' + val.replace('- $', '');
      }
      else if (decimalIndex == 4) {
        val = '- $0' + val.replace('- $', '');
      }
    }
  }

  if (commaSeparate) {
    return commaSeparateNumber(val);
  }
  else {
    return val;
  }
}

function commaSeparateNumber(val) {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return val;
}
